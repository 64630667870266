/* eslint-disable import/prefer-default-export */
import {last} from "common/lib/ext/array";
import I18n from "common/lib/i18n";

const i18nFrom = (context) => {
  if(!context) { return; }
  if(context.i18n) { return context.i18n; }
  if(!context.current) { return; }

  const {current: {organization}, locale} = context;
  return new I18n(locale, organization);
};

export function errorFrom(content, context = {}) {
  if(typeof content !== "object") { return content; }

  const i18n = i18nFrom(context);

  return Object.keys(content).map((key) => (
    content[key].map((value) => {
      const attribute = last(key.split("."));

      return [i18n?.t(`attributes.${attribute}`) || attribute, value].join(" ").trim();
    })
  )).join(", ");
}
