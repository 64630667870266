import {useEffect, useState} from "react";
import {setModal} from "common/actions/modal";
import Error from "common/components/partials/form/error";
import Field from "common/components/partials/form/field";
import Submit from "common/components/partials/form/submit";
import Divider from "common/components/partials/modal/divider";
import useAppContext from "common/hooks/use-app-context";
import useCreateSession from "common/hooks/requests/use-create-session";
import useI18n from "common/hooks/use-i18n";

export default function Form() {
  const {current: {organization}, dispatch} = useAppContext();
  const i18n = useI18n();
  const [account, setAccount] = useState({});
  const [profile, setProfile] = useState({});
  const {
    error,
    loading,
    showVerifyCode,
    trigger: createSession
  } = useCreateSession({showError: false});
  const onAccountChange = ({target: {name, value}}) => setAccount({...account, [name]: value});
  const onProfileChange = ({target: {name, value}}) => setProfile({...profile, [name]: value});
  const onSubmit = (e) => {
    e.preventDefault();

    if(loading) { return; }

    createSession({params: {account, profile}});
  };
  const accountValue = (field) => account[field] || "";
  const profileValue = (field) => profile[field] || "";

  useEffect(() => {
    if(!showVerifyCode) { return; }

    dispatch(setModal({show: true, type: "Sessions.VerifyCode"}));
  }, [showVerifyCode]);

  return (
    <form onSubmit={onSubmit}>
      <section>
        {organization.options.capture_email ? (
          <Field
            name="email"
            namespace="user-login"
            onChange={onAccountChange}
            placeholder={i18n.t("attributes.example_email")}
            showLabel={true}
            type="email"
            value={accountValue("email")}
          />
        ) : (
          <Field
            name="external_id"
            namespace="user-login"
            onChange={onProfileChange}
            placeholder={i18n.t("attributes.external_id")}
            showLabel={true}
            type="text"
            value={profileValue("external_id")}
          />
        )}
        {organization.options.capture_password && (
          <>
            <Field
              name="password"
              namespace="user-login"
              onChange={onAccountChange}
              placeholder="********"
              showLabel={true}
              type="password"
              value={accountValue("password")}
            />
            <button
              className="block ml-auto"
              onClick={() => dispatch(setModal({show: true, email: accountValue("email"), type: "Sessions.ForgotPassword"}))}
              type="button"
            >
              {i18n.t("sessions.forgot_password")}
            </button>
          </>
        )}
        {error && <Error error={error} />}
      </section>
      <Divider />
      <section className="text-right">
        <Submit disabled={loading}>{i18n.t("sessions.login")}</Submit>
      </section>
    </form>
  );
}
