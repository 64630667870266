import useAppContext from "common/hooks/use-app-context";

// NOTE: This adds the locale without retriggering requests
export default function useLocale(useSWRNext) {
  return (key, fetcher, config) => {
    const {locale} = useAppContext();
    const swr = useSWRNext(
      key,
      (_key, ...options) => fetcher({locale: locale?.code, ..._key}, ...options),
      config
    );

    return swr;
  };
}
