import "common/lib/shakapacker-asset-fix";
import Rails from "@rails/ujs";
import {createRoot} from "react-dom/client";
import "whatwg-fetch";
import Application from "public/components/application";

Rails.start();

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll(".react-component").forEach((node) => {
    const props = JSON.parse(node.getAttribute("data"));
    const root = createRoot(node);

    root.render(<Application {...props} />);
  });
});
