import {useState} from "react";
import {setModal} from "common/actions/modal";
import Error from "common/components/partials/form/error";
import Field from "common/components/partials/form/field";
import Submit from "common/components/partials/form/submit";
import Modal from "common/components/partials/modal";
import Divider from "common/components/partials/modal/divider";
import useAppContext from "common/hooks/use-app-context";
import useVerifyCode from "common/hooks/requests/use-verify-code";
import useI18n from "common/hooks/use-i18n";

function Form() {
  const [form, setForm] = useState({});
  const i18n = useI18n();
  const {
    error,
    loading,
    resent,
    triggerResend: onResend,
    triggerVerify: verifyCode
  } = useVerifyCode({showError: false});
  const onChange = ({target: {name, value}}) => setForm({...form, [name]: value});
  const onSubmit = (e) => {
    e.preventDefault();

    if(loading) { return; }

    verifyCode({params: {account: {code: form.code}}});
  };
  const value = (field) => form[field] || "";

  return (
    <form onSubmit={onSubmit}>
      <section>
        <div className="mb-2">
          <p>{i18n.t("verify_code.sent")}. {i18n.t("verify_code.enter_below")}</p>
        </div>
        <Field
          name="code"
          namespace="verify"
          onChange={onChange}
          showLabel={true}
          type="text"
          value={value("code")}
        />
        {resent ? (
          <div key="resent" className="mb-2">
            <p>{i18n.t("verify_code.resent")}</p>
          </div>
        ) : (
          <div key="resend" className="mb-2">
            <span>{i18n.t("verify_code.not_seeing_email")} </span>
            <button className="font-bold" onClick={onResend} type="button">{i18n.t("verify_code.resend_code")}</button>
          </div>
        )}
        {error && <Error error={error} />}
      </section>
      <Divider />
      <section className="text-right">
        <Submit disabled={loading}>{i18n.t("submit")}</Submit>
      </section>
    </form>
  );
}

export default function VerifyCode() {
  const {dispatch, modal} = useAppContext();
  const i18n = useI18n();
  const onClose = () => { dispatch(setModal({show: false})); };
  const show = modal.show && modal.type === "Sessions.VerifyCode";
  if(!show) { return null; }

  return (
    <Modal onClose={onClose} size="sm" title={i18n.t("verify_code.heading")}>
      <Form />
    </Modal>
  );
}
