import {setModal} from "common/actions/modal";
import useAppContext from "common/hooks/use-app-context";
import useI18n from "common/hooks/use-i18n";
import * as Modals from "public/components/partials/sessions/modals";
import LocalePicker from "./locale-picker";

export default function SessionLinks() {
  const {current: {organization: {options}}, dispatch} = useAppContext();
  const i18n = useI18n();
  const isAdminResults = window.location.pathname.startsWith("/admin");
  const isLogin = window.location.pathname === "/login";
  const showAdmin = !isLogin && options.show_admin_login;
  const showLogin = !isLogin && (
    options.capture_password || (options.capture_external_id && !options.capture_email)
  );

  return (
    <>
      {!isAdminResults && (
        <div className="nav-item">
          <LocalePicker />
        </div>
      )}
      {!isAdminResults && (showLogin || showAdmin) && (
        <div className="border-gray border-t-solid border-t my-4 md:hidden" />
      )}
      {showLogin && (
        <div className="nav-item">
          <button
            className="nav-link"
            onClick={() => dispatch(setModal({show: true, type: "Sessions.Login.User"}))}
            type="button"
          >
            {i18n.t("sessions.login")}
          </button>
        </div>
      )}
      {showAdmin && (
        <div className="nav-item">
          <button
            className="nav-link"
            onClick={() => dispatch(setModal({show: true, type: "Sessions.Login.Admin"}))}
            type="button"
          >
            {i18n.t("sessions.admin_login")}
          </button>
        </div>
      )}
      {showAdmin && <Modals.AdminLogin />}
      {showLogin && <Modals.UserLogin />}
      {(showAdmin || showLogin) && <Modals.ForgotPassword />}
      {(showAdmin || showLogin) && <Modals.VerifyCode />}
    </>
  );
}
