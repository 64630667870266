import {v4 as createUUID} from "uuid";
import {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION
} from "common/actions/notifications";
import {errorFrom} from "common/lib/error";

export default function notifications({action, context, state = []}) {
  switch(action.type) {
    case CREATE_NOTIFICATION: {
      const {content, id, type} = action.notification;

      return [
        ...state,
        {
          content: errorFrom(content, context),
          id: id || createUUID(),
          type: type || "success"
        }
      ];
    }
    case DELETE_NOTIFICATION:
      return state.filter(({id}) => id !== action.notification.id);
    default:
      return state;
  }
}
