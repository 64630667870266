import DangerousHTML from "common/components/partials/dangerous-html";
import Markdown from "common/components/partials/markdown";
import useI18n from "common/hooks/use-i18n";

export default function RegistrationDisclaimer() {
  const i18n = useI18n();
  const text = i18n.t("registration_disclaimer");

  return (
    <div className="mb-2">
      {text ? (
        <Markdown>{text}</Markdown>
      ) : (
        <DangerousHTML
          html={i18n.t("sessions.tos_html", {
            button: i18n.t("submit"),
            terms_of_service: `<a href="https://www.traitify.com/legal" target="_blank">${i18n.t("terms_of_service")}</a>`,
            privacy_policy: `<a href="https://www.traitify.com/legal#privacy-policy" target="_blank">${i18n.t("privacy_policy")}</a>`
          })}
        />
      )}
    </div>
  );
}
