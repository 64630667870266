import {useState} from "react";
import Field from "common/components/partials/form/field";
import Submit from "common/components/partials/form/submit";
import useCreateSession from "common/hooks/requests/use-create-session";
import useI18n from "common/hooks/use-i18n";
import ForgotPassword from "./forgot-password";
import VerifyCode from "./verify-code";

export default function Login() {
  const [form, setForm] = useState({});
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const i18n = useI18n();
  const {
    loading,
    showVerifyCode,
    trigger: createSession
  } = useCreateSession({redirect: "/choose-organization"});
  const onChange = ({target: {name, value}}) => setForm({...form, [name]: value});
  const onSubmit = (e) => {
    e.preventDefault();

    if(loading) { return; }

    createSession({params: {account: form}});
  };
  const value = (field) => form[field] || "";

  return (
    <section className="container max-w-screen-sm my-2">
      <div className="text-heading mb-2">{i18n.t("sessions.login")}</div>
      <form onSubmit={onSubmit}>
        <Field
          autoComplete="email username"
          name="email"
          onChange={onChange}
          type="email"
          value={value("email") || ""}
        />
        <Field
          autoComplete="current-password"
          name="password"
          onChange={onChange}
          type="password"
          value={value("password") || ""}
        />
        <div>
          <Submit disabled={loading}>{i18n.t("sessions.login")}</Submit>
          <button className="btn-link mx-2" onClick={() => setShowForgotPassword(!showForgotPassword)} type="button">{i18n.t("sessions.forgot_password")}</button>
        </div>
      </form>
      {showVerifyCode && <VerifyCode />}
      {showForgotPassword && <ForgotPassword email={value("email")} />}
    </section>
  );
}
