import {setLocale} from "common/actions/locale";
import DefaultLocalePicker from "common/components/partials/locale-picker";
import useAppContext from "common/hooks/use-app-context";
import useI18n from "common/hooks/use-i18n";
import useToggle from "legacy/lib/hooks/use-toggle";

export default function LocalePicker() {
  const {current: {organization}, dispatch} = useAppContext();
  const i18n = useI18n();
  const [showDropdown, toggleShowDropdown] = useToggle();
  const onChange = (value) => {
    const newLocale = organization.locales.find(({code}) => code === value);

    newLocale && dispatch(setLocale(newLocale));
  };

  return (
    <>
      <DefaultLocalePicker className="hidden md:block" />
      <div className="md:hidden">
        <button className="flex items-center justify-between w-full" onClick={toggleShowDropdown} type="button">
          <span>{i18n.t("attributes.language")}</span>
          <i className={`fa ${showDropdown ? "fa-minus" : "fa-plus"}`} />
        </button>
        {showDropdown && (
          <div className="mt-2 px-2">
            {organization.locales.map(({code, name}) => (
              <button key={code} className="block btn-link no-underline" onClick={() => onChange(code)} type="button">{name}</button>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
