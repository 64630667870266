import PropTypes from "prop-types";
import useAppContext from "common/hooks/use-app-context";
import useI18n from "common/hooks/use-i18n";

function Box({admin = false, organization, signup = false}) {
  return (
    <a className="bg-light border-t-lg border-theme group no-underline p-4 relative rounded-sm" href={`/choose-organization?subdomain=${organization.subdomain}`}>
      {admin && (
        <div className="absolute bg-theme left-0 p-0.5 rounded-sm text-light top-[-3px]">ADMIN</div>
      )}
      <div className="bg-dark mx-auto text-4xl text-light h-[52px] leading-[52px] w-[52px] group-hover:bg-theme">{organization.name[0]}</div>
      <div>
        {signup ? (
          <div className="my-1 text-heading text-default">Signup for<br />{organization.name}</div>
        ) : (
          <>
            <div className="my-1 group-hover:text-theme text-heading text-default">{organization.name}</div>
            <div className="group-hover:text-theme">{organization.url.split("://")[1]}</div>
          </>
        )}
      </div>
    </a>
  );
}

Box.propTypes = {
  admin: PropTypes.bool,
  organization: PropTypes.shape({
    name: PropTypes.string.isRequired,
    subdomain: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired,
  signup: PropTypes.bool
};

export default function ChooseOrganization() {
  const {current: {account, organization, user: currentUser}} = useAppContext();
  const i18n = useI18n();

  return (
    <section className="container text-center">
      <div className="text-heading">{i18n.t("login.heading")}</div>
      <div className="my-3">{i18n.t("login.subheading")}</div>
      <div className="gap-6 grid md:grid-cols-3">
        {!currentUser && (
          <Box organization={{...organization, name: organization.remote.name}} signup={true} />
        )}
        {account.users.map((user) => (
          <Box key={user.id} admin={user.admin} organization={user.organization} />
        ))}
      </div>
    </section>
  );
}
