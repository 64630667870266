import PropTypes from "prop-types";
import {setLocale} from "common/actions/locale";
import Field from "common/components/partials/form/field";
import useAppContext from "common/hooks/use-app-context";
import useDidMount from "common/hooks/use-did-mount";
import useDidUpdate from "common/hooks/use-did-update";
import useI18n from "common/hooks/use-i18n";
import Locale from "common/lib/locale";

function LocaleField({onChange, profile}) {
  const {current: {organization: {locales}}, dispatch, locale: currentLocale} = useAppContext();
  const i18n = useI18n();
  const onLocaleChange = (value) => {
    onChange({target: {name: "primary_language", value}});
  };

  useDidMount(() => {
    const code = profile.primary_language;
    const locale = code && Locale.fromUser(code);

    if(!locale) { return onLocaleChange(currentLocale.code); }
    if(code !== locale.code) { onLocaleChange(locale.code); }
    if(currentLocale.code !== locale.code) { dispatch(setLocale(locale)); }
  });

  useDidUpdate(() => {
    const code = profile.primary_language;
    const locale = code && Locale.fromUser(code);

    if(!locale || currentLocale.code !== locale.code) {
      onLocaleChange(currentLocale.code);
    }
  }, [currentLocale]);

  if(locales.length === 0) { return null; }

  return (
    <Field
      label={i18n.t("attributes.language")}
      name="primary_language"
      namespace="profile"
      onChange={onChange}
      type="locale"
    />
  );
}

LocaleField.propTypes = {
  onChange: PropTypes.func.isRequired,
  profile: PropTypes.shape({primary_language: PropTypes.string}).isRequired
};

export default LocaleField;
