import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import useSWR from "swr";
import {assign as redirect} from "common/actions/location";
import {setModal} from "common/actions/modal";
import Error from "common/components/partials/form/error";
import Field from "common/components/partials/form/field";
import Submit from "common/components/partials/form/submit";
import Divider from "common/components/partials/modal/divider";
import useAppContext from "common/hooks/use-app-context";
import useCreateSession from "common/hooks/requests/use-create-session";
import useI18n from "common/hooks/use-i18n";
import AdminForm from "./admin/form";
import UserForm from "./user/form";

const getSsoLink = ({options, organization}) => {
  if(options.provider !== "SAML - Equus") { return "/saml/init"; }

  return `${organization.url.replace(organization.subdomain, "app")}/saml/equus/init`;
};

function Form({admin = false}) {
  const {current: {organization}, dispatch} = useAppContext();
  const i18n = useI18n();
  const {data: session, isLoading: initialLoading} = useSWR({url: "/api/public/session/new"});
  const [form, setForm] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [ssoLink, setSsoLink] = useState(null);
  const [username, setUsername] = useState(null);
  const [usernameError, setUsernameError] = useState(null);
  const scopes = ["All", admin ? "Admin" : "User"];
  const {
    error: sessionError,
    loading,
    showVerifyCode,
    trigger: createSession
  } = useCreateSession({showError: false});

  useEffect(() => {
    if(!showVerifyCode) { return; }

    dispatch(setModal({show: true, type: "Sessions.VerifyCode"}));
  }, [showVerifyCode]);

  if(initialLoading) { return null; }

  const ssoOptions = session.sso_options.filter(({scope}) => scopes.includes(scope));
  if(ssoOptions.length === 0) { return admin ? <AdminForm /> : <UserForm />; }

  // NOTE: Logic gets messy if organization uses SSO while allowing guests without emails/passwords
  const unsupported = !organization.options.capture_email || !organization.options.capture_password;
  if(!admin && unsupported) { return <UserForm />; }

  const error = usernameError || sessionError;
  const namespace = admin ? "admin-login" : "user-login";
  const onChange = ({target: {name, value}}) => {
    if(["email", "external_id"].includes(name)) {
      setUsername(value);
      setShowForm(false);
      setSsoLink(null);
    }

    setForm({...form, [name]: value});
  };
  const onSubmit = (e) => {
    e.preventDefault();

    if(loading) { return; }
    if(showForm) {
      createSession({params: {account: {...form}}});
      return;
    }

    const domain = username.split("@").pop();
    const match = ssoOptions
      .filter(({required_email_domains: domains}) => domains)
      .find(({required_email_domains: domains}) => (
        domains.split(",").map((d) => d.trim()).includes(domain)
      ));
    if(match) { return redirect(getSsoLink({options: match, organization})); }

    const allowForm = ssoOptions.some(({allow_guests: allowGuests}) => allowGuests);
    const options = ssoOptions.find(({required_email_domains: domains}) => !domains);
    if(allowForm) {
      setShowForm(true);
      setSsoLink(options && getSsoLink({options, organization}));
      return;
    }
    if(options) { return redirect(getSsoLink({options, organization})); }

    setUsernameError({email: [i18n.t("errors.messages.invalid")]});
  };
  const value = (field) => form[field] || "";

  return (
    <form onSubmit={onSubmit}>
      <section>
        <Field
          name="email"
          namespace={namespace}
          onChange={onChange}
          placeholder={i18n.t("attributes.example_email")}
          showLabel={true}
          type="email"
          value={value("email")}
        />
        {showForm && (
          <>
            <Field
              name="password"
              namespace={namespace}
              onChange={onChange}
              placeholder="********"
              showLabel={true}
              type="password"
              value={value("password")}
            />
            <button
              className="block ml-auto"
              onClick={() => dispatch(setModal({show: true, email: value("email"), type: "Sessions.ForgotPassword"}))}
              type="button"
            >
              {i18n.t("sessions.forgot_password")}
            </button>
          </>
        )}
        {error && <Error error={error} />}
      </section>
      <Divider />
      <section className="flex gap-2 justify-between">
        {ssoLink ? <a className="btn-green" href={ssoLink}>Login with SSO</a> : <div />}
        {(!ssoLink || showForm) && <Submit disabled={loading}>{i18n.t("submit")}</Submit>}
      </section>
    </form>
  );
}

Form.propTypes = {admin: PropTypes.bool};

export default Form;
