import {createBrowserRouter, redirect} from "react-router-dom";
import ErrorBoundary from "common/components/layout/router-error-boundary";
import Container from "./components/layout/container";
import * as Pages from "./components/pages";

export default function createRouter({current: {account}}) {
  // NOTE: Login and ChooseOrganization check for account but most other routes should check user
  const requireNoAccount = () => {
    if(account) { return redirect("/choose-organization"); }

    return null;
  };
  const requireAccount = () => {
    if(!account) { return redirect("/login"); }

    return null;
  };

  return createBrowserRouter(
    [{
      children: [
        {Component: Pages.Sessions.New, path: "/"},
        {Component: Pages.ChooseOrganization, loader: requireAccount, path: "/choose-organization"},
        {Component: Pages.Login, loader: requireNoAccount, path: "/login"},
        {Component: Pages.Sessions.New, path: "/sessions/new"},
        {Component: Pages.NotFound}
      ],
      Component: Container,
      ErrorBoundary
    }]
  );
}
