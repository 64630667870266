import PropTypes from "prop-types";
import {useState} from "react";

function Notification({content, onClose = null, type = "success", ...props}) {
  const [show, setShow] = useState(true);

  if(!show) { return null; }

  const onClick = (e) => {
    e.preventDefault();

    onClose ? onClose() : setShow(false);
  };

  let typeClasses;
  switch(type) {
    case "error": typeClasses = "bg-error text-error"; break;
    default: typeClasses = "bg-success text-success";
  }

  return (
    <div className={`mb-2 py-3 pl-4 pr-10 relative rounded-lg ${typeClasses}`} role="alert" {...props}>
      <div>{content}</div>
      <button aria-label="Close" className="absolute btn-icon inset-y-0 right-0 flex items-center mr-4" onClick={onClick} type="button">
        <i className="fas fa-times" />
      </button>
    </div>
  );
}

Notification.propTypes = {
  content: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  type: PropTypes.string
};

export default Notification;
