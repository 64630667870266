import "@fortawesome/fontawesome-free/js/all";
import {useMemo, useReducer} from "react";
import {RouterProvider} from "react-router-dom";
import {SWRConfig} from "swr";
import Context from "app/context";
import fetcher from "app/request/fetcher";
import useLocaleFetch from "common/hooks/middleware/use-locale-fetch";
import useScrollbarWidth from "common/hooks/use-scrollbar-width";
import Reducer, {createInitialState} from "public/reducer";
import createRouter from "public/router";
import "public/assets/style.scss";

window.FontAwesome.config.autoReplaceSvg = "nest";

export default function Application(props) {
  const [reducer, dispatch] = useReducer(Reducer, props, createInitialState);
  const state = useMemo(() => ({...reducer, dispatch}), [reducer, dispatch]);
  const router = useMemo(() => createRouter(state), [!!state.current.user]);
  const swrConfig = useMemo(() => ({
    fetcher,
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    use: [useLocaleFetch]
  }), []);

  useScrollbarWidth();

  return (
    <Context.Provider value={state}>
      <SWRConfig value={swrConfig}>
        <RouterProvider router={router} />
      </SWRConfig>
    </Context.Provider>
  );
}
