import PropTypes from "prop-types";
import {useEffect, useMemo} from "react";
import useSWR from "swr";
import Field from "common/components/partials/form/field";
import useQueryParams from "common/hooks/use-query-params";

function DataFields({changes, onChange: updateChanges, original}) {
  const queryParams = useQueryParams();
  const {data: request} = useSWR({url: "/api/public/account"});
  const fields = useMemo(() => request?.data_fields || [], [request]);

  useEffect(() => {
    if(fields.length === 0) { return; }

    const newChanges = [];

    fields.filter(({key}) => queryParams.has(key)).forEach((field) => {
      const change = changes.find(({data_field_id: id}) => id === field.id);
      const value = queryParams.get(field.key);

      if(change && change.data === value) { return; }

      newChanges.push({data: value, data_field_id: field.id, id: change ? change.id : null});
    });

    updateChanges(newChanges);
  }, [fields]);

  const onChange = ({target: {name, value}}) => {
    const id = +name;
    const data = original.find((d) => d.data_field_id === id) || {};
    const currentChanges = changes.filter((d) => d.data_field_id !== id);
    if(data.data !== value) {
      let change = changes.find((d) => d.data_field_id === id);
      if(!change) { change = {data_field_id: id, id: data.id}; }
      currentChanges.push({...change, data: value});
    }

    updateChanges(currentChanges);
  };

  const value = (field) => {
    let data = changes.find((d) => d.data_field_id === field.id);
    if(!data) { data = original.find((d) => d.data_field_id === field.id); }

    return data ? data.data : "";
  };

  if(fields.length === 0) { return null; }

  return (
    <>
      {fields.map((field) => {
        const props = {
          label: field.name,
          name: field.id.toString(),
          namespace: "data",
          onChange,
          required: field.required,
          value: value(field)
        };

        if(field.options) {
          props.children = field.options.map((option) => (
            <option key={option} value={option}>{option}</option>
          ));
          props.type = "select";
        }

        return <Field key={field.id} {...props} />;
      })}
    </>
  );
}

DataFields.propTypes = {
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.string.isRequired,
      data_field_id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  original: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.string.isRequired,
      data_field_id: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
};

export default DataFields;
