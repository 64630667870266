import PropTypes from "prop-types";
import useI18n from "common/hooks/use-i18n";
import {errorFrom} from "common/lib/error";

function Error({error = null}) {
  const i18n = useI18n();
  if(!error) { return null; }

  return <div className="mb-3 text-red">{errorFrom(error, {i18n})}</div>;
}

Error.propTypes = {
  error: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ])
};

export default Error;
