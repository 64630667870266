import {Fragment} from "react";
import useAppContext from "common/hooks/use-app-context";
import useI18n from "common/hooks/use-i18n";
import {times} from "common/lib/ext/array";
import {isWhite} from "common/lib/ext/color";
import logoBlack from "public/assets/images/paradox/traitify-by-paradox.svg";
import logoWhite from "public/assets/images/paradox/traitify-by-paradox-white.svg";

export default function Footer() {
  const {current: {organization}} = useAppContext();
  const i18n = useI18n();

  const links = times(3).map((index) => index + 1).map((index) => ({
    link: i18n.t(`footer_link_${index}`),
    text: i18n.t(`footer_text_${index}`)
  })).filter(({link, text}) => link && text);
  const navbarColor = organization.options.navbar_color;
  const logo = navbarColor && !isWhite(navbarColor) ? logoWhite : logoBlack;

  return (
    <footer className="footer" role="contentinfo">
      <div className="container flex justify-between">
        <div>
          {links.map(({link, text}, index) => (
            <Fragment key={text}>
              {index !== 0 && <span className="text-light"> | </span>}
              <a className="text-light" href={link}>{text}</a>
            </Fragment>
          ))}
        </div>
        <a href="/sessions/new" title="Return to homepage">
          <img src={logo} width="170" height="60" alt="Traitify" />
        </a>
      </div>
    </footer>
  );
}
