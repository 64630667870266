import {SET_LOCALE} from "common/actions/locale";

export default ({action, state = null}) => {
  switch(action.type) {
    case SET_LOCALE:
      return {...action.locale};
    default:
      return state;
  }
};
