import {useState} from "react";
import useSWRMutation from "swr/mutation";
import {assign} from "common/actions/location";
import {createNotification} from "common/actions/notifications";
import useAppContext from "common/hooks/use-app-context";

export default function useVerifyCode({redirect, showError = true} = {}) {
  const {dispatch} = useAppContext();
  const [error, setError] = useState(null);
  const [resent, setResent] = useState(false);
  const {isLoading: loadingResend, trigger: triggerResend} = useSWRMutation({
    method: "POST",
    url: "/api/public/session/resend_code"
  }, {
    onSuccess: (data) => {
      setError(data.errors ? data.errors : null);
      setResent(!data.errors);

      if(data.errors && showError) { dispatch(createNotification({content: data.errors, type: "error"})); }
    }
  });
  const {isLoading: loadingVerify, trigger: triggerVerify} = useSWRMutation({
    method: "POST",
    url: "/api/public/session/verify_code"
  }, {
    onSuccess: (data) => {
      setError(data.errors ? data.errors : null);
      setResent(false);

      if(data.errors && showError) { dispatch(createNotification({content: data.errors, type: "error"})); }
      if(data.success) { assign(data.redirect || redirect || "/"); }
    }
  });

  return {
    error,
    loading: loadingResend || loadingVerify,
    resent,
    triggerResend,
    triggerVerify
  };
}
