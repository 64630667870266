import combineReducers from "common/lib/combine-reducers";
import current from "common/reducers/current";
import locale from "common/reducers/locale";
import modal from "common/reducers/modal";
import notifications from "common/reducers/notifications";

const defaultReducer = ({state}) => state;

export const createInitialState = (props) => ({
  app: {...props.app},
  current: props.current || {},
  locale: props.locale,
  modal: {show: false},
  notifications: (props.notifications || []),
  original: props.original || {}
});

export default combineReducers({
  app: defaultReducer,
  current,
  locale,
  modal,
  notifications,
  original: defaultReducer
});
