import PropTypes from "prop-types";
import {useMemo} from "react";
import useSWR from "swr";
import Field from "common/components/partials/form/field";

function MajorField({onChange: onUpdate, value = ""}) {
  const {data: request} = useSWR({url: "/api/public/account"});
  const majors = useMemo(() => request?.majors || [], [request]);
  const onChange = ({target: {name, value: newValue}}) => (
    onUpdate({target: {name, value: newValue ? +newValue : null}})
  );
  if(majors.length === 0) { return null; }

  return (
    <Field
      name="major_id"
      namespace="user"
      onChange={onChange}
      type="select"
      value={value}
    >
      {majors.map(({id, title}) => (
        <option key={id} value={id}>{title}</option>
      ))}
    </Field>
  );
}

MajorField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default MajorField;
