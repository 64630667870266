import logoWhite from "public/assets/images/paradox/traitify-by-paradox-white.svg";
import useAppContext from "common/hooks/use-app-context";
import {isWhite} from "common/lib/ext/color";

export default function Logo() {
  const {current: {organization: {options}}} = useAppContext();
  const isDefaultLogo = options.navbar_logo.url.includes("traitify-by-paradox");
  const isWhiteLogo = isDefaultLogo && options.navbar_color && !isWhite(options.navbar_color);
  const logo = isWhiteLogo ? logoWhite : options.navbar_logo.url;

  return <img alt="Traitify by Paradox" src={logo} />;
}
