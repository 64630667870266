import PropTypes from "prop-types";
import useAppContext from "common/hooks/use-app-context";
import useI18n from "common/hooks/use-i18n";
import {toSnakeCase} from "common/lib/ext/string";

// TODO: Recommendation, Report
function PdfLink({adminToken, assessmentID}) {
  const {current: {recommendation, report}} = useAppContext();
  const i18n = useI18n();
  let queryParams = {adminToken};

  if(recommendation) { queryParams.recommendation_id = recommendation.recommendation_id; }
  if(report) {
    queryParams.perspective = toSnakeCase(report.perspective);
    queryParams.view = report.view;
  }
  queryParams = Object.keys(queryParams).map((key) => (
    `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
  )).join("&");

  const href = `/personality/${assessmentID}.pdf?${queryParams}`;
  const title = i18n.t("results.print");

  return (
    <div className="nav-item">
      <a className="nav-link" href={href} rel="noopener noreferrer" target="_blank" title={title}>
        <i className="fa fa-print" />
      </a>
    </div>
  );
}

PdfLink.propTypes = {
  adminToken: PropTypes.string.isRequired,
  assessmentID: PropTypes.string.isRequired
};

export default PdfLink;
