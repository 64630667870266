import PropTypes from "prop-types";

function Submit({children, className = null, disabled = false}) {
  const skipColor = className && className.includes("btn-");
  const classes = [
    className,
    !skipColor && "btn-theme",
    disabled && "btn-disabled"
  ].filter(Boolean).join(" ");

  return <button className={classes} type="submit">{children}</button>;
}

Submit.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
};

export default Submit;
