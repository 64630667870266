import {useState} from "react";
import {displayInitials} from "common/actions/current";
import useAppContext from "common/hooks/use-app-context";
import AccountLinks from "./account-links";
import AdminLinks from "./admin-links";
import Logo from "./logo";
import SessionLinks from "./session-links";
import Theme from "./theme";
import style from "./style.scss";

export default function Navbar() {
  const {current: {account, profile}} = useAppContext();
  const [show, setShow] = useState(false);
  const isAdminResults = window.location.pathname.startsWith("/admin");
  const isSessions = ["/login", "/sessions/new"].includes(window.location.pathname)
    || window.location.pathname === "/";

  return (
    <header className="header">
      <div className="flex flex-wrap md:flex-nowrap items-center">
        <div className="flex-grow">
          <a className={style.logo} href="/">
            <Logo />
          </a>
        </div>
        <a className={style.skipLink} href="#main" title="Skip Navigation">
          Skip Navigation
        </a>
        <button className="nav-toggle md:hidden" onClick={() => setShow(!show)} type="button">
          {(account || !isSessions) ? (
            <>{displayInitials({account, profile}) || <i className="fa fa-user fa-lg" />} <i className="fa fa-angle-down fa-lg ml-1" /></>
          ) : (
            <i className="fa fa-bars fa-lg" />
          )}
        </button>
        <nav aria-label="Main Menu" className={`${show ? "flex" : "hidden"} basis-full md:basis-auto md:flex gap-2`}>
          {!account && <SessionLinks />}
          {account && <AccountLinks isSessions={isSessions} />}
          {isAdminResults && <AdminLinks />}
        </nav>
      </div>
      <Theme />
    </header>
  );
}
