import {useEffect} from "react";
import useAppContext from "common/hooks/use-app-context";

export default function useBackground() {
  const {current: {organization}} = useAppContext();

  useEffect(() => {
    let image = `url(${organization.background.url})`;
    if(organization.darken_background) {
      image = `linear-gradient(to right, rgba(0,0,0, 0.6), rgba(0,0,0, 0.6)), ${image}`;
    }

    const element = document.querySelector("#main");
    element.style.backgroundImage = image;
    element.style.backgroundSize = "cover";

    return () => {
      element.style.backgroundImage = null;
      element.style.backgroundSize = null;
    };
  }, [organization]);
}
