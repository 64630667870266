import {useEffect} from "react";
import useQueryParams from "common/hooks/use-query-params";

export default function useIframe() {
  const iframe = useQueryParams().get("lt") === "iframe";

  useEffect(() => {
    if(!iframe) { return; }
    if(document.cookie.length > 0) { return; }
    if(window.top.location !== window.location) { return; }

    window.top.location.href = window.location.href.replace("lt=iframe", "");
  }, [iframe]);
}
