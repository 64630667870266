import PropTypes from "prop-types";
import {useMemo} from "react";
import Field from "common/components/partials/form/field";
import useI18n from "common/hooks/use-i18n";

const validate = ({complex, password, passwordConfirmation}) => {
  if(!password === null) { return null; }

  const validations = {};

  if(/(?=.*[a-z])/.test(password)) { validations.lowercase = true; }
  if(/(?=.*[A-Z])/.test(password)) { validations.uppercase = true; }
  if(/(?=.*[0-9])/.test(password)) { validations.number = true; }
  if(password === passwordConfirmation) { validations.match = true; }
  if(complex) {
    if(/(?=.*[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])/.test(password)) { validations.special = true; }
    if(/.{15,}/.test(password)) { validations.total = true; }
  } else if(/.{8,}/.test(password)) {
    validations.total = true;
  }

  return validations;
};

function Requirement({children, valid = false}) {
  return (
    <div>
      <i className={`fa mr-0.5 ${valid ? "fa-check text-green" : "fa-times text-red"}`} />
      {children}
    </div>
  );
}

Requirement.propTypes = {children: PropTypes.node.isRequired, valid: PropTypes.bool};

function PasswordField({complex = false, onChange, password = null, passwordConfirmation = null}) {
  const i18n = useI18n();
  const validations = useMemo(() => (
    validate({complex, password, passwordConfirmation})
  ), [complex, password, passwordConfirmation]);

  return (
    <div className="flex gap-2 mb-2">
      <div className="flex-1">
        <Field name="password" namespace="account" onChange={onChange} type="password" value={password} />
        {validations && (
          <div className="-mt-1">
            {complex ? [
              <Requirement key="total" valid={validations.total}>{i18n.t("validations.password.total", {minimum: 15})}</Requirement>,
              <Requirement key="special" valid={validations.special}>{i18n.t("validations.password.special")}</Requirement>
            ] : (
              <Requirement key="total" valid={validations.total}>{i18n.t("validations.password.total", {minimum: 8})}</Requirement>
            )}
            <Requirement key="uppercase" valid={validations.uppercase}>{i18n.t("validations.password.uppercase")}</Requirement>
            <Requirement key="lowercase" valid={validations.lowercase}>{i18n.t("validations.password.lowercase")}</Requirement>
            <Requirement key="number" valid={validations.number}>{i18n.t("validations.password.number")}</Requirement>
          </div>
        )}
      </div>
      <div className="flex-1">
        <Field name="password_confirmation" namespace="account" onChange={onChange} type="password" value={passwordConfirmation} />
        {validations && (
          <div className="-mt-1">
            <Requirement key="match" valid={validations.match}>{i18n.t("validations.password.match")}</Requirement>
          </div>
        )}
      </div>
    </div>
  );
}

PasswordField.propTypes = {
  complex: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  password: PropTypes.string,
  passwordConfirmation: PropTypes.string
};

export default PasswordField;
