import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import LocalePicker from "common/components/partials/locale-picker";
import useI18n from "common/hooks/use-i18n";
import except from "common/lib/ext/object/except";

function Field({
  children = null,
  label: _label = null,
  name,
  namespace = null,
  placeholder = null,
  showLabel = true,
  type = "text",
  ..._props
}) {
  const i18n = useI18n();
  const id = namespace ? [namespace, name].join("-") : name;
  const label = _label || i18n.t(`attributes.${name}`);
  const props = {
    id,
    ..._props,
    name
  };

  return (
    <label className="block mb-2" htmlFor={id}>
      {showLabel && <div>{label}</div>}
      {{
        date: (
          // NOTE: Excludes id because that prevents close on select
          <DatePicker
            dateFormat="yyyy-MM-dd"
            dropdownMode="select"
            onChange={(value) => props.onChange({target: {name, value}})}
            placeholderText={placeholder || label}
            showYearDropdown={true}
            selected={props.value ? new Date(props.value) : null}
            wrapperClassName="block"
            {...except(props, ["id", "onChange", "value"])}
          />
        ),
        locale: <LocalePicker placeholder={placeholder || label} {...props} />,
        select: (
          <select {...props}>
            <option value="">{placeholder || label}</option>
            {children}
          </select>
        )
      }[type] || (
        <input placeholder={placeholder || label} type={type} {...props} />
      )}
    </label>
  );
}

Field.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  namespace: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  showLabel: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.any /* eslint-disable-line react/forbid-prop-types */
};

export default Field;
