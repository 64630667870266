import {useEffect} from "react";
import useSWR from "swr";
import {setModal} from "common/actions/modal";
import {createNotification} from "common/actions/notifications";
import Loading from "common/components/pages/loading";
import VideoModal from "common/components/partials/modals/video";
import useAppContext from "common/hooks/use-app-context";
import useQueryParams from "common/hooks/use-query-params";
import useI18n from "common/hooks/use-i18n";
import videoThumbnail from "public/assets/images/session/video-thumbnail.png";
import AccountForm from "public/components/partials/account/form";
import useBackground from "./use-background";
import useIframe from "./use-iframe";

const getShibbolethLink = ({organization, session}) => {
  if(!organization.options.shibboleth) { return; }

  return [
    `${session.shibboleth}/Shibboleth.sso/Login?entityID=${organization.options.shibboleth}`,
    `&target=${session.shibboleth}/auth/shibboleth/callback?subdomain=${organization.subdomain}`
  ].join("");
};

const getSsoLink = ({admin, organization, session}) => {
  if(!session.sso_options) { return false; }

  const scopes = ["All", admin ? "Admin" : "User"];
  // NOTE: Only 1 button is shown, even if multiple SSO Options are enabled
  const options = session.sso_options.find(({scope}) => scopes.includes(scope));
  if(!options) { return false; }
  if(options.provider !== "SAML - Equus") { return "/saml/init"; }

  return `${organization.url.replace(organization.subdomain, "app")}/saml/equus/init`;
};

export default function New() {
  const {current: {account, organization}, dispatch, notifications} = useAppContext();
  const i18n = useI18n();
  const {data: session, isLoading: loading} = useSWR({url: "/api/public/session/new"});
  const {options} = organization;
  const params = useQueryParams();

  useBackground();
  useIframe();
  useEffect(() => {
    if(!session) { return; }
    if(!session.redirect) { return; }
    if(notifications.length > 0) { return; }

    dispatch(createNotification({content: i18n.t("sessions.signin_first"), type: "error"}));
  }, [session]);

  if(loading) { return <Loading />; }

  const ssoLink = getSsoLink({
    admin: params.get("admin") === "true",
    organization,
    session
  });
  const ssoOnly = ssoLink && params.get("sso") === "true";
  const shibbolethLink = getShibbolethLink({organization, session});
  const openVideoModal = () => {
    dispatch(setModal({show: true, type: "Video", url: options.video_url}));
  };

  return (
    <section className="container md:flex">
      <div className="bg-default p-2 rounded-sm md:inline-block md:ml-auto md:mr-10 md:my-10">
        <div className="m-auto w-40">
          <div className="mb-2 text-heading">{i18n.t(account ? "sessions.complete_registration" : "get_started")}</div>
          {!account && (
            <div className="mb-2">{options.registration_text ? options.registration_text : i18n.t("sessions.registration_text")}</div>
          )}
          {shibbolethLink && <div className="mb-2"><a href={shibbolethLink} className="btn-theme">Login with Shibboleth</a></div>}
          {ssoLink && <div className="mb-2"><a href={ssoLink} className="btn-theme" data-method="POST">Login with SSO</a></div>}
          {!shibbolethLink && !ssoOnly && <AccountForm type="Registration" />}
          {options.show_registration_video && options.video_url && (
            <>
              <div className="border-t-md my-2" />
              <div className="mx-8">
                <button type="button" onClick={openVideoModal}>
                  <img alt="Registration Video" src={videoThumbnail} />
                </button>
              </div>
              <VideoModal />
            </>
          )}
        </div>
      </div>
    </section>
  );
}
