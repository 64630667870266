import {setModal} from "common/actions/modal";
import Modal from "common/components/partials/modal";
import useAppContext from "common/hooks/use-app-context";

export default function Video() {
  const {dispatch, modal} = useAppContext();
  const onClose = () => { dispatch(setModal({show: false})); };
  const show = modal.show && modal.type === "Video";
  if(!show) { return null; }

  return (
    <Modal background="transparent" onClose={onClose} size="xl">
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={true}
        className="h-[80vh]"
        height="100%"
        src={modal.url}
        title="Organization Video Player"
        width="100%"
      />
    </Modal>
  );
}
