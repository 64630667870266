import PropTypes from "prop-types";
import {setLocale} from "common/actions/locale";
import useAppContext from "common/hooks/use-app-context";
import useI18n from "common/hooks/use-i18n";

function LocalePicker({onChange = null, placeholder = "", ...props}) {
  const {current: {organization}, dispatch, locale} = useAppContext();
  const i18n = useI18n();
  const onLocaleChange = ({target: {name, value}}) => {
    const newLocale = organization.locales.find(({code}) => code === value);

    onChange && onChange({target: {name, value}});
    newLocale && dispatch(setLocale(newLocale));
  };

  return (
    <select onChange={onLocaleChange} value={locale.code} {...props}>
      <option value="">{placeholder || i18n.t("attributes.language")}</option>
      {organization.locales.map(({code, name}) => (
        <option key={code} value={code}>{name}</option>
      ))}
    </select>
  );
}

LocalePicker.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default LocalePicker;
