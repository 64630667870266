import {
  SET_CURRENT,
  SET_CURRENT_ORGANIZATION,
  SET_CURRENT_USER
} from "common/actions/current";

export default ({action, state = {}}) => {
  switch(action.type) {
    case SET_CURRENT:
      return {...action.current};
    case SET_CURRENT_ORGANIZATION:
      return {...state, organization: {...action.organization}};
    case SET_CURRENT_USER:
      return {...state, user: {...action.user}};
    default:
      return state;
  }
};
