import {useEffect, useState} from "react";
import {setModal} from "common/actions/modal";
import Error from "common/components/partials/form/error";
import Field from "common/components/partials/form/field";
import Submit from "common/components/partials/form/submit";
import Divider from "common/components/partials/modal/divider";
import useAppContext from "common/hooks/use-app-context";
import useCreateSession from "common/hooks/requests/use-create-session";
import useI18n from "common/hooks/use-i18n";

export default function Form() {
  const {dispatch} = useAppContext();
  const [form, setForm] = useState({});
  const i18n = useI18n();
  const {
    error,
    loading,
    showVerifyCode,
    trigger: createSession
  } = useCreateSession({showError: false});
  const onChange = ({target: {name, value}}) => setForm({...form, [name]: value});
  const onSubmit = (e) => {
    e.preventDefault();

    if(loading) { return; }

    createSession({params: {account: form}});
  };
  const value = (field) => form[field] || "";

  useEffect(() => {
    if(!showVerifyCode) { return; }

    dispatch(setModal({show: true, type: "Sessions.VerifyCode"}));
  }, [showVerifyCode]);

  return (
    <form onSubmit={onSubmit}>
      <section>
        <Field
          name="email"
          namespace="admin-login"
          onChange={onChange}
          placeholder={i18n.t("attributes.example_email")}
          showLabel={true}
          type="email"
          value={value("email")}
        />
        <Field
          name="password"
          namespace="admin-login"
          onChange={onChange}
          placeholder="********"
          showLabel={true}
          type="password"
          value={value("password")}
        />
        <button
          className="block ml-auto"
          onClick={() => dispatch(setModal({show: true, email: value("email"), type: "Sessions.ForgotPassword"}))}
          type="button"
        >
          {i18n.t("sessions.forgot_password")}
        </button>
        {error && <Error error={error} />}
      </section>
      <Divider />
      <section className="text-right">
        <Submit disabled={loading}>{i18n.t("sessions.login")}</Submit>
      </section>
    </form>
  );
}
