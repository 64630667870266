/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import PropTypes from "prop-types";
import {useEffect} from "react";
import Divider from "./divider";

// NOTE: Tailwind can't track string interpolation in classes
const backgrounds = {
  transparent: "bg-transparent"
};

function Modal({
  background = null,
  children,
  className = null,
  onClose,
  show = true,
  size = "lg",
  title = null,
  ...props
}) {
  const classNames = [
    className,
    "modal",
    !show && "hidden"
  ].filter(Boolean);
  const containerClassNames = [
    background && backgrounds[background],
    "modal-container",
    `modal-${size}`
  ];

  useEffect(() => {
    if(!show) { return; }

    const onEscape = (event) => { if(event.key === "Escape") { onClose(); } };

    window.addEventListener("keydown", onEscape);

    return () => { window.removeEventListener("keydown", onEscape); };
  }, [show]);

  return (
    <div className={classNames.join(" ")} onClick={onClose} role="presentation" {...props}>
      <section className={containerClassNames.join(" ")} onClick={(e) => e.stopPropagation()} role="dialog">
        <button aria-label="Close" className="modal-close" onClick={onClose} type="button">
          <i className="fa fa-times" />
        </button>
        {title && (
          <>
            <div className="modal-title">
              <h2>{title}</h2>
            </div>
            <Divider />
          </>
        )}
        <div className="modal-content">
          {children}
        </div>
      </section>
    </div>
  );
}

Modal.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  size: PropTypes.string,
  title: PropTypes.string
};

export default Modal;
