import {toQueryString} from "common/lib/ext/object";
import headers from "./headers";

export const request = ({locale, params, url: _url, ..._options}) => {
  const options = {..._options};
  let url = _url;

  if(["POST", "PUT"].includes(options.method)) {
    options.body = JSON.stringify(params);
  } else {
    url = `${url}?${toQueryString(params || {})}`;
  }

  return fetch(url, {
    method: "GET",
    ...options,
    headers: headers({locale})
  }).then((response) => response.json());
};

export default (_options, otherOptions) => {
  let options = {..._options};

  if(otherOptions?.arg) {
    options = {...options, ...otherOptions.arg};
  }

  return request(options);
};
