export const SET_CURRENT = "SET_CURRENT";
export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const setCurrent = (current) => ({type: SET_CURRENT, current});
export const setCurrentOrganization = (organization) => (
  {type: SET_CURRENT_ORGANIZATION, organization}
);
export const setCurrentUser = (user) => ({type: SET_CURRENT_USER, user});

export const displayNameFrom = (object) => {
  if(!object) { return undefined; }
  if(object.name) { return object.name; }

  const name = [object.first_name, object.last_name].filter(Boolean).join(" ");
  return name || object.external_id || object.email;
};

export const displayName = ({account, profile}) => (
  displayNameFrom(profile) || displayNameFrom(account)
);

export const displayInitialsFrom = (object) => {
  const fullName = displayNameFrom(object);
  if(!fullName) { return undefined; }

  return fullName.split(" ").map((name) => name[0]).join("").toUpperCase();
};

export const displayInitials = ({account, profile}) => (
  displayInitialsFrom(profile) || displayInitialsFrom(account)
);
