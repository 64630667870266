import useAppContext from "common/hooks/use-app-context";

export default function Theme() {
  const {current: {organization: {options}}} = useAppContext();
  const style = options.navbar_color && `
    footer.footer { background-color: #${options.navbar_color} !important; }
    header.header { background-color: #${options.navbar_color} !important; }
    header nav select { color: ${options.navbar_hover_color} !important; }
    header .nav-toggle {
      background: none !important;
      border-radius: 0 !important;
    }
    header .nav-dropdown {
      border: 1px solid #${options.navbar_color};
    }
    header .nav-link {
      background-color: white !important;
      border-color: #${options.navbar_color} !important;
      color: #${options.navbar_color} !important;
    }
    header .nav-item {
      border-color: #${options.navbar_color} !important;
    }
    header .nav-link:focus,
    header .nav-link:hover {
      background-color: ${options.navbar_hover_color} !important;
      color: white !important;
    }
    .bg-theme { background-color: ${options.navbar_hover_color} !important; }
    .border-theme { border-color: ${options.navbar_hover_color} !important; }
    .text-theme { color: ${options.navbar_hover_color} !important; }
    .footer { background: #${options.navbar_color} !important; }
  `;

  return style && <style>{style}</style>;
}
