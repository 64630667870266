import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import {setCurrent} from "common/actions/current";
import {assign} from "common/actions/location";
import {createNotification} from "common/actions/notifications";
import DangerousHTML from "common/components/partials/dangerous-html";
import Field from "common/components/partials/form/field";
import Submit from "common/components/partials/form/submit";
import Loading from "common/components/partials/loading";
import useAppContext from "common/hooks/use-app-context";
import useI18n from "common/hooks/use-i18n";
import useQueryParams from "common/hooks/use-query-params";
import {dig} from "common/lib/ext/object";
import DataFields from "./data-fields";
import LocaleField from "./locale-field";
import MajorField from "./major-field";
import PackageField from "./package-field";
import PasswordField from "./password-field";
import RegistrationDisclaimer from "./registration-disclaimer";

const blankChanges = {account: {}, data: [], profile: {}, user: {}};

function Form({type = null}) {
  const {current: {organization}, dispatch} = useAppContext();
  const i18n = useI18n();
  const queryParams = useQueryParams();
  const [changes, setChanges] = useState({...blankChanges});
  const [domainError, setDomainError] = useState(false);
  const [externalIDError, setExternalIDError] = useState(false);
  const [original, setOriginal] = useState(null);
  const [packageID, setPackageID] = useState(null);
  const [passwordlessError, setPasswordlessError] = useState(false);
  const {departments, options} = organization;
  const registration = type === "Registration";

  useSWR({url: "/api/public/account"}, {
    onSuccess: ({record: account}) => {
      if(!account) { return setOriginal({}); }

      const user = account.users.find(({organization_id: id}) => id === organization.id);
      if(user) { return setOriginal({account, data: user.data, profile: user.profile, user}); }

      setOriginal({account});
    }
  });

  const {trigger: triggerPasswordlessLogin} = useSWRMutation({
    method: "POST",
    url: "/api/public/password/send_login_email"
  }, {
    onSuccess: ({errors, message, success}) => {
      if(!success) { return dispatch(createNotification({content: errors, type: "error"})); }

      dispatch(createNotification({content: message, type: "success"}));
    }
  });
  const {trigger: triggerSubmit} = useSWRMutation({
    method: dig(original, "account", "id") ? "PUT" : "POST",
    url: "/api/public/account"
  }, {
    onSuccess: ({current, errors, record: account, redirect, success}) => {
      if(!success) {
        setDomainError((errors.email || []).includes(i18n.t("errors.messages.domain")));
        setExternalIDError((errors["users.external_id"] || []).includes(i18n.t("errors.messages.taken")));
        setPasswordlessError((errors.email || []).includes(i18n.t("errors.messages.taken")));

        return dispatch(createNotification({content: errors, type: "error"}));
      }

      if(registration) {
        return assign(
          packageID && packageID !== "skip"
            ? `/recommendation/survey?package_id=${packageID}`
            : redirect || "/assessment"
        );
      }

      const user = account.users.find(({organization_id: id}) => id === organization.id);

      setChanges({...blankChanges});
      setOriginal({account, data: user.data, profile: user.profile, user});
      dispatch(createNotification({content: i18n.t("settings.updated"), type: "success"}));
      dispatch(setCurrent(current));
    }
  });

  useEffect(() => {
    const newChanges = {...changes};

    [
      {model: "account", fields: ["birthdate", "email", "first_name", "last_name", "locale"]},
      {model: "profile", fields: ["external_id"]},
      {model: "user", fields: ["account_type", "department", "title"]}
    ].forEach(({fields, model}) => {
      newChanges[model] = {...newChanges[model]};

      fields.filter((field) => queryParams.has(field)).forEach((field) => {
        newChanges[model][field] = queryParams.get(field);
      });
    });

    if(registration) { newChanges.user.invited = false; }

    setChanges(newChanges);
  }, [queryParams.toString()]);

  if(!original) { return <Loading />; }

  const onChange = (model, {target: {name, value}}) => {
    const currentChanges = {...changes[model]};
    const currentValue = dig(original, model, name);
    if(currentValue === value || (!currentValue && !value)) {
      delete currentChanges[name];
    } else {
      currentChanges[name] = value;
    }
    setChanges({...changes, [model]: currentChanges});
  };
  const pendingChanges = (
    Object.keys(changes.account).length > 0
    || changes.data.length > 0
    || Object.keys(changes.profile).length > 0
    || Object.keys(changes.user).length > 0
  );
  const value = (model, name, {fallback = ""} = {}) => {
    let current = changes[model][name];
    if(current == null) { current = dig(original, model, name); }

    return current == null ? fallback : current;
  };

  const onAccountChange = (e) => onChange("account", e);
  const onDataChange = (data) => setChanges({...changes, data});
  const onPasswordlessLogin = () => {
    const params = {account: {email: value("profile", "email") || value("account", "email")}};

    triggerPasswordlessLogin({params});
  };
  const onProfileChange = (e) => onChange("profile", e);
  const onSubmit = (e) => {
    e.preventDefault();

    if(!pendingChanges) { return; }
    if(registration && options.capture_package && !packageID) {
      return dispatch(createNotification({
        content: {package: [i18n.t("errors.messages.blank")]},
        type: "error"
      }));
    }

    const params = {
      account: {
        ...changes.account,
        data_attributes: [...changes.data],
        users_attributes: [{
          ...changes.user,
          id: original.user?.id,
          organization_id: organization.id,
          profile_attributes: {...changes.profile}
        }]
      }
    };

    if(changes.profile.date_of_birth) { params.account.birthdate = changes.profile.date_of_birth; }
    if(changes.profile.email) { params.account.email = changes.profile.email; }
    if(changes.profile.first_name) { params.account.first_name = changes.profile.first_name; }
    if(changes.profile.last_name) { params.account.last_name = changes.profile.last_name; }
    if(changes.profile.primary_language) {
      params.account.locale = changes.profile.primary_language;
    }

    triggerSubmit({params});
  };
  const onUserChange = (e) => onChange("user", e);

  return (
    <form onSubmit={onSubmit}>
      {domainError && (
        <DangerousHTML html={i18n.t("sessions.invalid_domain")} />
      )}
      {options.capture_first_and_last_name && (
        <div className="flex gap-1">
          <div className="flex-1">
            <Field
              name="first_name"
              namespace="profile"
              onChange={onProfileChange}
              value={value("profile", "first_name") || value("account", "first_name")}
            />
          </div>
          <div className="flex-1">
            <Field
              name="last_name"
              namespace="profile"
              onChange={onProfileChange}
              value={value("profile", "last_name") || value("account", "last_name")}
            />
          </div>
        </div>
      )}
      {options.capture_email && (
        <>
          <Field
            name="email"
            namespace="profile"
            onChange={onProfileChange}
            type="email"
            value={value("profile", "email") || value("account", "email")}
          />
          {passwordlessError && (
            <div className="mb-2">
              {i18n.t("sessions.passwordless_login.pre")} <button className="btn-link" onClick={onPasswordlessLogin} type="button">{i18n.t("click_here")}</button>. {i18n.t("sessions.passwordless_login.post")}
            </div>
          )}
        </>
      )}
      {options.capture_password && !original.account?.password_digest && (
        <PasswordField
          complex={options.require_complex_password}
          onChange={onAccountChange}
          password={value("account", "password")}
          passwordConfirmation={value("account", "password_confirmation")}
        />
      )}
      {options.capture_birthdate && (
        <Field
          label={i18n.t("attributes.birthdate")}
          name="date_of_birth"
          namespace="profile"
          onChange={onProfileChange}
          type="date"
          value={value("profile", "date_of_birth") || value("account", "birthdate")}
        />
      )}
      <LocaleField onChange={onProfileChange} profile={original.profile || {}} />
      {options.capture_external_id && (
        <>
          <Field
            name="external_id"
            namespace="profile"
            onChange={onProfileChange}
            value={value("profile", "external_id")}
          />
          {externalIDError && (
            <div className="mb-2">
              {i18n.t("sessions.returning_user")} {i18n.t("sessions.signin_first")}
            </div>
          )}
        </>
      )}
      {options.capture_account_type && options.account_types.length > 0 && (
        <Field
          name="account_type"
          namespace="user"
          onChange={onUserChange}
          type="select"
          value={value("user", "account_type")}
        >
          {options.account_types.map((accountType) => (
            <option key={accountType} value={accountType}>{accountType}</option>
          ))}
        </Field>
      )}
      {options.capture_major && (
        <MajorField onChange={onUserChange} value={value("user", "major_id")} />
      )}
      {options.capture_department && departments.length > 0 && (
        <Field
          name="department"
          namespace="user"
          onChange={onUserChange}
          type="select"
          value={value("user", "department")}
        >
          {departments.map(({id, name}) => (
            <option key={id} value={name}>{name}</option>
          ))}
        </Field>
      )}
      {options.capture_title && (
        <Field
          name="title"
          namespace="user"
          onChange={onUserChange}
          value={value("user", "title")}
        />
      )}
      <DataFields
        changes={changes.data}
        onChange={onDataChange}
        original={original.data || []}
      />
      {registration && options.capture_package && (
        <PackageField setValue={setPackageID} value={packageID} />
      )}
      {registration && <RegistrationDisclaimer />}
      <div>
        <Submit disabled={!pendingChanges}>{i18n.t(registration ? "submit" : "save_changes")}</Submit>
      </div>
    </form>
  );
}

Form.propTypes = {type: PropTypes.string};

export default Form;
