import {useState} from "react";
import useSWRMutation from "swr/mutation";
import {createNotification} from "common/actions/notifications";
import useAppContext from "common/hooks/use-app-context";

export default function useForgotPassword({showError = true} = {}) {
  const {dispatch} = useAppContext();
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const {isLoading: loading, trigger} = useSWRMutation({
    method: "POST",
    url: "/api/public/password/send_reset_email"
  }, {
    onSuccess: (data) => {
      setError(data.errors ? data.errors : null);
      setMessage(data.message ? data.message : null);

      if(data.errors && showError) { dispatch(createNotification({content: data.errors, type: "error"})); }
      if(data.success) { dispatch(createNotification({content: data.message, type: "success"})); }
    }
  });

  return {
    error,
    loading,
    message,
    trigger
  };
}
