import {SET_MODAL} from "common/actions/modal";

export default ({action, state = null}) => {
  switch(action.type) {
    case SET_MODAL:
      return {...action.modal};
    default:
      return state;
  }
};
