import {useCallback, useState} from "react";
import useSWRMutation from "swr/mutation";
import {assign} from "common/actions/location";
import {createNotification} from "common/actions/notifications";
import useAppContext from "common/hooks/use-app-context";

export default function useCreateSession({redirect, showError = true}) {
  const {dispatch} = useAppContext();
  const [error, setError] = useState(null);
  const [showVerifyCode, setShowVerifyCode] = useState(false);
  const {isLoading: loading, trigger} = useSWRMutation({
    method: "POST",
    url: "/api/public/session"
  }, {
    onSuccess: (data) => {
      setError(data.errors ? data.errors : null);
      setShowVerifyCode(data.require_2fa);

      if(data.errors && showError) { dispatch(createNotification({content: data.errors, type: "error"})); }
      if(data.success && !data.require_2fa) { assign(data.redirect || redirect || "/"); }
    }
  });
  const resetState = useCallback(() => {
    setError(null);
    setShowVerifyCode(false);
  }, []);

  return {
    error,
    loading,
    resetState,
    showVerifyCode,
    trigger
  };
}
