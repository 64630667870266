import {useState} from "react";
import Field from "common/components/partials/form/field";
import Submit from "common/components/partials/form/submit";
import useVerifyCode from "common/hooks/requests/use-verify-code";
import useI18n from "common/hooks/use-i18n";

export default function VerifyCode() {
  const [code, setCode] = useState("");
  const i18n = useI18n();
  const {
    loading,
    resent,
    triggerResend: onResend,
    triggerVerify: verifyCode
  } = useVerifyCode({redirect: "/choose-organization"});
  const onChange = ({target: {value}}) => setCode(value);
  const onSubmit = (e) => {
    e.preventDefault();

    verifyCode({params: {account: {code}}});
  };

  return (
    <form onSubmit={onSubmit}>
      <hr className="border-border my-3" />
      <div className="mb-2">
        <p>{i18n.t("verify_code.sent")}. {i18n.t("verify_code.enter_below")}</p>
      </div>
      <Field
        name="code"
        namespace="verification"
        onChange={onChange}
        type="text"
        value={code}
      />
      {resent ? (
        <div key="resent" className="mb-2">
          <p>{i18n.t("verify_code.resent")}</p>
        </div>
      ) : (
        <div key="resend" className="mb-2">
          <span>{i18n.t("verify_code.not_seeing_email")} </span>
          <button onClick={onResend} type="button">{i18n.t("verify_code.resend_code")}</button>
        </div>
      )}
      <div>
        <Submit disabled={loading}>{i18n.t("submit")}</Submit>
      </div>
    </form>
  );
}
