import {useEffect, useMemo, useReducer} from "react";
import I18n from "common/lib/i18n";
import useAppContext from "./use-app-context";

export default function useI18n() {
  const {current: {organization}, locale} = useAppContext();
  const i18n = useMemo(() => new I18n(locale, organization), [locale, organization]);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    i18n.onLoad = () => forceUpdate();

    return () => { i18n.onLoad = null; };
  }, [i18n]);

  return i18n;
}
