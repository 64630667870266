import PropTypes from "prop-types";
import {displayInitials} from "common/actions/current";
import useAppContext from "common/hooks/use-app-context";
import useI18n from "common/hooks/use-i18n";

function Links({mobile = false}) {
  const {current: {organization, user}} = useAppContext();
  const i18n = useI18n();
  const {options} = organization;
  const itemClass = `nav-item ${mobile ? "md:hidden" : ""}`;

  return (
    <>
      {user && (
        <>
          {user.admin && (
            <div className={itemClass}>
              <a className="nav-link" href="/admin">{i18n.t("navbar.admin_portal")}</a>
            </div>
          )}
          {options.show_results && (
            <div className={itemClass}>
              <a className="nav-link" href="/personality">{i18n.t("navbar.personality")}</a>
            </div>
          )}
          {options.show_settings_in_dropdown && (
            <div className={itemClass}>
              <a className="nav-link" href="/settings">{i18n.t("navbar.settings")}</a>
            </div>
          )}
          {options.show_contact_us_in_dropdown && (
            <div className={itemClass}>
              <a className="nav-link" href={`mailto:${organization.contact_emails}`} title="Contact Support">
                <i className="fa fa-life-ring" /> {i18n.t("navbar.contact")}
              </a>
            </div>
          )}
        </>
      )}
      <div className={itemClass}>
        <a className="nav-link" href="/sessions" data-method="delete">{i18n.t("logout")}</a>
      </div>
    </>
  );
}

Links.propTypes = {mobile: PropTypes.bool};

function AccountLinks({isSessions}) {
  const {current: {account, profile}} = useAppContext();

  return (
    <>
      <div className="nav-item nav-hover">
        <button className="nav-toggle hidden md:flex" onClick={() => {}} type="button">
          {(account || !isSessions) && (
            <>{displayInitials({account, profile}) || <i className="fa fa-user fa-lg" />} <i className="fa fa-angle-down fa-lg ml-1" /></>
          )}
        </button>
        <div className="nav-dropdown">
          <Links />
        </div>
      </div>
      <Links mobile={true} />
    </>
  );
}

AccountLinks.propTypes = {isSessions: PropTypes.bool.isRequired};

export default AccountLinks;
