import {Outlet} from "react-router-dom";
import ErrorBoundary from "common/components/layout/error-boundary";
import Notifications from "common/components/layout/notifications";
import useAppContext from "common/hooks/use-app-context";
import Footer from "./footer";
import Navbar from "./navbar";

export default function Container() {
  const {app: {iframe}} = useAppContext();

  return (
    <div className="flex flex-col h-screen">
      {!iframe && <Navbar />}
      <Notifications />
      <main className="flex-auto" id="main" role="main" tabIndex="-1">
        <ErrorBoundary><Outlet /></ErrorBoundary>
      </main>
      {!iframe && <Footer />}
    </div>
  );
}
