import {useEffect, useState} from "react";
import {setModal} from "common/actions/modal";
import Error from "common/components/partials/form/error";
import Field from "common/components/partials/form/field";
import Submit from "common/components/partials/form/submit";
import Modal from "common/components/partials/modal";
import Divider from "common/components/partials/modal/divider";
import useAppContext from "common/hooks/use-app-context";
import useForgotPassword from "common/hooks/requests/use-forgot-password";
import useI18n from "common/hooks/use-i18n";

function ExternalID() {
  const {current: {organization}} = useAppContext();
  const i18n = useI18n();
  const preventDefault = (e) => e.preventDefault();

  return (
    <form onSubmit={preventDefault}>
      <section>
        <Field
          name="email"
          namespace="forgot-password"
          onChange={preventDefault}
          showLabel={true}
          type="email"
          value={organization.contact_emails}
        />
      </section>
      <div className="mb-2">
        <p>{i18n.t("sessions.reset_password_instructions_for_external_id")}</p>
      </div>
    </form>
  );
}

function Form() {
  const {dispatch, modal} = useAppContext();
  const [form, setForm] = useState({email: modal.email});
  const i18n = useI18n();
  const {
    error,
    loading,
    message,
    trigger: createPasswordReset
  } = useForgotPassword({showError: false});
  const onChange = ({target: {name, value}}) => setForm({...form, [name]: value});
  const onSubmit = (e) => {
    e.preventDefault();

    if(loading) { return; }

    createPasswordReset({params: {account: {email: form.email}}});
  };
  const value = (field) => form[field] || "";

  useEffect(() => {
    if(!message) { return; }

    dispatch(setModal({show: false}));
  }, [message]);

  return (
    <form onSubmit={onSubmit}>
      <section>
        <Field
          autoComplete="email username"
          name="email"
          namespace="forgot-password"
          onChange={onChange}
          placeholder={i18n.t("attributes.example_email")}
          showLabel={true}
          type="email"
          value={value("email")}
        />
        {error && <Error error={error} />}
      </section>
      <div className="mb-2">
        <p>{i18n.t("sessions.reset_password_instructions")}</p>
      </div>
      <Divider />
      <section className="text-right">
        <Submit disabled={loading}>{i18n.t("submit")}</Submit>
      </section>
    </form>
  );
}

export default function ForgotPassword() {
  const {current: {organization}, dispatch, modal} = useAppContext();
  const i18n = useI18n();
  const onClose = () => { dispatch(setModal({show: false})); };
  const show = modal.show && modal.type === "Sessions.ForgotPassword";
  if(!show) { return null; }

  return (
    <Modal onClose={onClose} size="sm" title={i18n.t("forgot_password.heading")}>
      {organization.options.capture_email ? (
        <Form />
      ) : (
        <ExternalID onClose={onClose} />
      )}
    </Modal>
  );
}
