import Rails from "@rails/ujs";

export default ({locale}) => {
  const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json; charset=utf-8",
    "X-CSRF-Token": Rails.csrfToken()
  };

  if(locale) { headers["Override-Language"] = locale; }

  return headers;
};
