import PropTypes from "prop-types";
import {Component} from "react";

export default class ErrorBoundary extends Component {
  static propTypes = {children: PropTypes.node.isRequired};
  constructor(props) {
    super(props);
    this.state = {error: null};
  }
  static getDerivedStateFromError(error) {
    return {error: `${error.name}: ${error.message}`};
  }
  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo); // eslint-disable-line no-console
  }
  render() {
    if(!this.state.error) { return this.props.children; }

    return (
      <div className="container mx-auto my-20 text-center">
        <h1>Error</h1>
        <p>Please reload the page and try again. If the problem persists, please contact support</p>
      </div>
    );
  }
}
