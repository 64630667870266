import PropTypes from "prop-types";
import {useEffect, useMemo} from "react";
import useSWR from "swr";
import Field from "common/components/partials/form/field";
import useAppContext from "common/hooks/use-app-context";
import useI18n from "common/hooks/use-i18n";

function PackageField({setValue, value = null}) {
  const {current: {organization}} = useAppContext();
  const i18n = useI18n();
  const {data: request} = useSWR({url: `/v1/packages?organization_uuid=${organization.uuid}`});
  const packages = useMemo(() => request || [], [request]);

  useEffect(() => {
    if(value) { return; }
    if(!request) { return; }
    if(request.length === 0) { setValue("skip"); }
  }, [request]);

  if(packages.length === 0) { return null; }

  return (
    <Field
      label={i18n.t("package_label")}
      name="package_id"
      onChange={(e) => setValue(e.target.value)}
      type="select"
      value={value || ""}
    >
      {packages.map(({id, name}) => (
        <option key={id} value={id}>{name}</option>
      ))}
    </Field>
  );
}

PackageField.propTypes = {
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string
};

export default PackageField;
