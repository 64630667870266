import PropTypes from "prop-types";
import {useState} from "react";
import Field from "common/components/partials/form/field";
import Submit from "common/components/partials/form/submit";
import useForgotPassword from "common/hooks/requests/use-forgot-password";
import useI18n from "common/hooks/use-i18n";

function ForgotPassword({email: initialEmail}) {
  const [email, setEmail] = useState(initialEmail || "");
  const i18n = useI18n();
  const {loading, trigger: createPasswordReset} = useForgotPassword();
  const onChange = ({target: {value}}) => setEmail(value);
  const onSubmit = (e) => {
    e.preventDefault();

    createPasswordReset({params: {account: {email}}});
  };

  return (
    <form onSubmit={onSubmit}>
      <hr className="border-border my-3" />
      <Field
        autoComplete="email username"
        name="email"
        namespace="forgot-password"
        onChange={onChange}
        type="email"
        value={email}
      />
      <div className="mb-2">
        <p>{i18n.t("sessions.reset_password_instructions")}</p>
      </div>
      <div>
        <Submit disabled={loading}>{i18n.t("submit")}</Submit>
      </div>
    </form>
  );
}

ForgotPassword.propTypes = {email: PropTypes.string.isRequired};

export default ForgotPassword;
