import {setModal} from "common/actions/modal";
import Modal from "common/components/partials/modal";
import useAppContext from "common/hooks/use-app-context";
import useI18n from "common/hooks/use-i18n";
import Form from "../form";

export default function AdminLogin() {
  const {dispatch, modal} = useAppContext();
  const i18n = useI18n();
  const onClose = () => { dispatch(setModal({show: false})); };
  const show = modal.show && modal.type === "Sessions.Login.Admin";
  if(!show) { return null; }

  return (
    <Modal onClose={onClose} size="sm" title={i18n.t("sessions.admin_login")}>
      <Form admin={true} />
    </Modal>
  );
}
